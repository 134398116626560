import React, { Component } from 'react'
import api from '../services/api'
import ModalViagem from '../components/modalViagem'
import Header from '../components/header'
import { expiredToken, getToken } from '../util/helpers'
import $ from 'jquery'
import { nextTick } from 'q'

import 'antd/dist/antd.css';
// import './index.css';
import { Table, Button, Layout } from 'antd';
import { Select } from 'antd';
import Navigation from '../components/header';

const { Content } = Layout;
const { Option } = Select;

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
  },
  {
    title: 'Saída',
    dataIndex: 'dt_saida',
  },
  {
    title: 'Chegada',
    dataIndex: 'dt_chegada',
  },
  {
    title: 'Origem',
    dataIndex: 'cidade_origem'
  },
  {
    title: 'Destino',
    dataIndex: 'cidade_destino'
  },
  {
    title: '# Passagens',
    dataIndex: 'quantidade_restante'
  }
];

export default class Viagem extends Component {
  state = {
    page: "Viagem",
    itens: [],
    barcos: [],
    idBarco: 0,
    idViagem: '',
    selectedRowKeys: [],
    loading: false,
    travelsLoading: false
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  listarViagensBarco = async (idBarco) => {
    try {
      await this.setState({ travelsLoading: true });
      const response = await api.get(`embarcacoes/${idBarco}/viagens`, { headers: { Authorization: "Bearer " + getToken() }});
      this.setState({ itens: response.data, travelsLoading: false });
    } catch (error) {
      expiredToken(error);
    }
  }

  listarBarcos = async () => {
    try {
      const response = await api.get('embarcacoes', { headers: { Authorization: "Bearer " + getToken()}})
      this.setState({barcos: response.data})      
    } catch (error) {
      expiredToken(error)
    }
  }

  UpdateAfterInsert = () => {
    this.listarViagensBarco(this.state.idBarco)
  }

  InputNome = e => {
    this.setState({ nome: e.target.value })
  }

  InputIdBarco = async (value, option) => {
    await this.setState({ idBarco: option.props.id });
    this.listarViagensBarco(this.state.idBarco);
  }

  excluirViagem = async (id_viagem) => {
    try {
      await api.delete(
        `viagens/${id_viagem}`,
        {
          headers: { 
            Authorization: `Bearer ${getToken()}` 
          }
        })
        .then(response => {
          console.log(response);
        })      
    } catch (error) {
      expiredToken(error);
    }
  }

  remove = async () => {
    this.setState({ loading: true });
    
    const { selectedRowKeys } = this.state;
    const promises = selectedRowKeys.map(async item => {
      return await this.excluirViagem(item);
    });

    Promise.all(promises)
      .then(() => {
        this.setState({
          selectedRowKeys: [],
          loading: false
        });
        this.listarViagensBarco(this.state.idBarco);
      })
      .catch(error => console.log(error));
  }

  async componentDidMount() {
    this.listarBarcos();
  }

  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const barcos = this.state.barcos.map(item => <Option id={item.id} key={item.id} value={item.nome}> {item.nome} </Option>);

    return (
      <Layout>
        <Content style={{ padding: '0 50px', marginTop: 70 }} >
          <div className="container">
            <div className="my-3 p-3 bg-white rounded shadow-sm" >
              <div>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Selecione o barco para exibir suas viagens"
                  optionFilterProp="value"
                  onChange={this.InputIdBarco}
                  filterOption={(input, option) =>
                    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  { barcos }
                </Select>
              </div>
              <div className="d-block text-right mt-3">
                {/* <Button onClick={this.UpdateAfterInsert}>Atualizar</Button> */}
                <ModalViagem update={this.UpdateAfterInsert}/>
              </div>
              <div>
                <div style={{ marginBottom: 16 }}>
                  <Button type="danger" onClick={this.remove} disabled={!hasSelected} loading={loading}>
                    Remover
                  </Button>
                  <span style={{ marginLeft: 8 }}>
                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                  </span>
                </div>
                <Table rowSelection={rowSelection} columns={columns} dataSource={this.state.itens} rowKey="id" loading={ this.state.travelsLoading } />
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    )
  }

}
