import React from 'react';
import { Drawer, Form, Button, Col, Row, Input, message } from 'antd';
import api from '../services/api';
import viacepApi from '../services/viacep';
import { getToken, expiredToken } from '../util/helpers';

class PortForm extends React.Component {
  state = { 
    visible: false,
    address: {}
  };

  showDrawer = () => {
    this.setState({
      visible: true,
      isLoading: false
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChangeCep = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    this.setState({ address: {} });
    const { data } = await viacepApi.get(`ws/${value}/json`);
    if (data.cep) {
      data.bairro = data.bairro || "N/A";
      data.logradouro = data.logradouro || "N/A";
    }
    this.setState({ address: data });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (error, values) => {
      if (!error) {
        try {
          this.setState({ isLoading: true });
          if (!this.state.address.cep) {
            message.config({ maxCount: 2 });
            return message.warning('Insira um CEP válido');
          }
          const headers = {
            Authorization: `Bearer ${getToken()}`
          };
          const requestData = {
            nome: values.name,
            cep: this.state.address.cep,
            bairro: this.state.address.bairro,
            logradouro: this.state.address.logradouro,
            numero: values.number,
            cidade_id: this.props.city.id
          };
          await api.post('portos', requestData, { headers });
          this.setState({ isLoading: false, visible: false, address: {} });
          this.props.form.resetFields();
          this.props.update();
        } catch (error) {
          expiredToken(error);
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Button
          type="primary" 
          style={{ backgroundColor: '#52b788', borderColor: '#52b788' }}
          onClick={this.showDrawer}
        >
          Adicionar porto
        </Button>
        <Drawer
          title="Adicionar um novo porto"
          width={600}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            <Row gutter={16}>
              <Col span={18}>
                <Form.Item label="Nome">
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Insira o nome do porto' }],
                  })(<Input required={true} placeholder="Nome do porto" />)}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="CEP">
                  {getFieldDecorator('cep', {
                    rules: [{ required: true, message: 'Insira o cep' }],
                  })(<Input required={true} placeholder="CEP" onChange={this.onChangeCep} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="Bairro">
                  {getFieldDecorator('district')(<Input disabled={true} placeholder={this.state.address.bairro}/>)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Logradouro">
                  <Input disabled={true} placeholder={this.state.address.logradouro} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Numero">
                  {getFieldDecorator('number', {
                    rules: [{ required: true, message: 'Insira o número' }],
                  })(<Input required={true} placeholder="Número" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Cidade">
                  <Input disabled={true} placeholder={this.state.address.localidade}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="UF">
                  <Input disabled={true} placeholder={this.state.address.uf}/>
                </Form.Item>
              </Col>
            </Row>
            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                Cancelar
              </Button>
              <Button 
                type="primary"
                htmlType="submit"
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Drawer>
      </div>
    );
  }
}

export default Form.create()(PortForm);