import React, { Component } from 'react'
import api from "../services/api"
import { expiredToken, getToken } from '../util/helpers'

import 'antd/dist/antd.css';
import { Modal, Button, Input, Select, Form, InputNumber, message, Col, Row, Icon } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

class modalBarco extends Component {

  state = {
      loading: false,
      visible: false
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleSubmit =  e => {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return console.log(err);
      }

      await this.submit(values);
    });
  }

  submit = async (values) => {
    this.setState({ loading: true });
  
    try {
      const body = {
        'nome': values.name,
        'capacidade_total': values.capacity,
        'qtd_passagem': values.ticketCount,
        'tipo_embarcacao': values.type,
        'descricao': values.description,
        'servicos': values.services
      };
      const headers = {
        Authotization: `Bearer ${getToken()}`
      };

      await api.post('embarcacoes', body, { headers: headers });
      message.success('Salvo com sucesso!', 4);
    } catch (error) {
      console.log(error.response);
      expiredToken(error);
      message.error(error.response.data.error, 4);
    } finally {
      this.setState({ loading: false, visible: false });
      this.props.update();
    }
  }

  render() {
    const { visible, loading } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Button 
          type="primary" 
          style={{ marginBottom: '20px' }} 
          onClick={this.showModal}
        > 
          Novo barco 
        </Button>
        <Modal
          title="Inserir nova embarcação"
          centered={true}
          width="720px"
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Return
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
              Submit
            </Button>,
          ]}
        >
          <Form layout="vertical">
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label="Nome">
                  {
                    getFieldDecorator('name', { rules: [{ required: true, message: 'Digite o nome da embarcação' }] })
                    (<Input placeholder="Digite o nome da embarcação" />)
                  }
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Tipo">
                  {
                    getFieldDecorator('type', { rules: [{ required: true, message: 'Selecione o tipo da embarcação'}]})
                    (
                      <Select placeholder="Selecione o tipo da embarcação">
                        <Option value="barco">Barco</Option>
                        <Option value="lancha">Lancha</Option>
                        <Option value="ferryboat">Ferry Boat</Option>
                        <Option value="balsa">Balsa</Option>
                      </Select>
                    )
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label="Capacidade total">
                  {
                    getFieldDecorator('capacity', { rules: [{ required: true, message: 'Digite a capacidade da embarcação'}]})
                    (
                      <InputNumber 
                        min={1} 
                        placeholder="Digite a capacidade da embarcação"
                        style={{ width: '100%' }} 
                      />
                    )
                  } 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Quantidade de passagens">
                  {
                    getFieldDecorator('ticketCount', { rules: [{ required: true, message: 'Digite o total de passagens disponíveis' }] })
                    (
                      <InputNumber 
                        min={1} 
                        placeholder="Digite o total de passagens disponíveis"
                        style={{ width: '100%' }}
                      />
                    )
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item label="Descrição">
                  {
                    getFieldDecorator('description')
                    (
                      <TextArea 
                        placeholder="Insira uma breve descrição da embarcação" 
                        rows={4}
                      >
                      </TextArea>)
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item label="Serviços">
                  {
                    getFieldDecorator('services')
                    (
                      <TextArea 
                        placeholder="Insira os serviços oferecidos pela embarcação" 
                        rows={4}
                      >
                      </TextArea>
                    )
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default Form.create({ name: 'new_ship '})(modalBarco);