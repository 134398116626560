import React, { Component } from 'react'
import api from '../services/api'
import ModalRota from '../components/modalRota'
import ModalRotaDup from '../components/modalRotaDup'
import $ from 'jquery'
import { expiredToken, getToken } from '../util/helpers'

import 'antd/dist/antd.css';
import './barco.css';
import { Table, Button, Layout, Tag } from 'antd';
import { Select } from 'antd';
import { Input, InputNumber, Popconfirm, Form } from 'antd';
import { message } from 'antd'
import Navigation from '../components/header';

const { Content } = Layout;
const { Option } = Select;

const EditableContext = React.createContext();

class EditableCell extends Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class Rota extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "Rota",
      data: [],
      barcos: [],
      idBarco: 0,
      editingKey: '',
      loading: false
    };
    this.columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        width: '10%',
        editable: false,
      },
      {
        title: 'Origem',
        dataIndex: 'cidade_origem',
        width: '15%',
        editable: false,
      },
      {
        title: 'Destino',
        dataIndex: 'cidade_destino',
        width: '15%',
        editable: false,
      },
      {
        title: 'Trecho',
        dataIndex: 'trecho_id',
        width: '10%',
        editable: false,
        render: value => {
          const text = value == null ? 'LINHA PRINCIPAL' : `LINHA ${value}`;
          const color = value == null ? 'volcano' : 'green';
          return (
            <span>          
              <Tag color={color}>
                {text}
              </Tag>
            </span>
        )}
      },
      {
        title: 'Transporte',
        dataIndex: 'valor_transporte',
        width: '10%',
        editable: true,
      },
      {
        title: 'Alimentacao',
        dataIndex: 'valor_alimentacao',
        width: '10%',
        editable: true,
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        width: '10%',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => this.save(form, record.key)}
                    style={{ marginRight: 5 }}
                  >
                    Save
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                <Button size="small" >
                  Cancel
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <div>
              <Button size="small" disabled={editingKey !== ''} onClick={() => this.edit(record.key)} style={{marginRight: '5px'}}>
                Edit
              </Button>
              <Popconfirm title="Sure to delete?" onConfirm={() =>  this.excluirRota(record.key)}>
                <Button type="danger" size="small" >
                  Delete
                </Button>
              </Popconfirm>
            </div>
          );
        },
      },
    ];
  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);
      
      if (index > -1) {
        this.salvarRota(key, row);
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: '' });
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: '' });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  listarRotas = async (id) => {
    try {
      await this.setState({ loading: true });
      const response = await api.get(`embarcacoes/${id}/rotas`, { headers: { Authorization: `Bearer ${getToken()}` } });
      this.setState({ 
        data: response.data.map(item => {
          item.key = item.id;
          return item;
          }),
        loading: false 
        });      
    } catch (error) {
      expiredToken(error);
    }
  }

  listarBarcos = async () => {
    try {
      const response = await api.get('embarcacoes', { headers: { Authorization: "Bearer " + getToken()}})
      this.setState({barcos: response.data})      
    } catch (error) {
      expiredToken(error)
    }
  }

  salvarRota = async (key, values) => {
    api.patch(`rotas/${key}`, values, { headers: { Authorization: "Bearer " + getToken() } })
      .then(response => {
        console.log(response);
        message.success('Atualizado com sucesso!', 4);
      })
      .catch(error => {
        console.log(error.response);
        expiredToken(error);
        message.error(error.response.data.error);
      });
  }

  UpdateAfterInsert = () => {
    this.listarRotas(this.state.idBarco)
  }

  InputIdBarco = async (value, option) => {
    await this.setState({ idBarco: option.props.id });
    this.listarRotas(this.state.idBarco);
  }

  async componentDidMount() {
    this.listarBarcos();
  }

  excluirRota = async (id_rota) => {
    try {
      await api.delete(`rotas/${id_rota}`, { headers: { Authorization: `Bearer ${getToken()}` } });
      message.success('Removido com sucesso!', 4);
      this.listarRotas();      
    } catch (error) {
      expiredToken(error);
      message.error(error.response.data.error, 4);
    }
  }


  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: (col.dataIndex === 'valor_transporte' || col.dataIndex === 'valor_alimentacao') ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const barcos = this.state.barcos.map(item => <Option id={item.id} key={item.id} value={item.nome}> {item.nome} </Option>);

    return (
      <Layout>
        <Content style={{ padding: '0 50px', marginTop: 70 }}>   
          <ModalRota rotas={this.state.data} idBarco={this.state.idBarco} update={this.UpdateAfterInsert} />
          <ModalRotaDup update={this.UpdateAfterInsert} />  
          <div className="my-3 p-3 bg-white rounded shadow-sm" style={{ width: '70%', float: 'none', margin: '0 auto' }}>
            <small className="d-block text-right mt-3" style={{marginBottom: '10px', width: '30px'}}>
              <Button type="primary" data-toggle="modal" data-target="#modalRota"> Novo registro </Button>
            </small> 
            <small className="d-block text-right mt-3" style={{marginBottom: '10px',  width: '30px'}}>
              <Button type="primary" data-toggle="modal" data-target="#modalRotaDup"> Duplicar rotas </Button>
            </small>       
            <div className="input-group mb-3">
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Selecione o barco para exibir suas rotas"
                optionFilterProp="value"
                onChange={this.InputIdBarco}
                filterOption={(input, option) =>
                  option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                { barcos }
              </Select>
            </div>            
            <EditableContext.Provider value={this.props.form}>
              <Table
                components={components}
                dataSource={this.state.data}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                  onChange: this.cancel,
                }}
                rowKey="id"
                loading={this.state.loading}
              />
            </EditableContext.Provider>
          </div>
        </Content>
      </Layout>
    )
  }

}

export default Form.create()(Rota);