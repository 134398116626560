import React, { Component } from 'react'
import api from '../services/api'
import { expiredToken } from '../util/helpers'

import 'antd/dist/antd.css';
import { Table, Input, Button, Icon } from 'antd';
import Highlighter from 'react-highlight-words';
import { Layout } from 'antd';
import Navigation from '../components/header.js';

const { Footer, Sider, Content } = Layout;

export default class Login extends Component {
  state = {
    page: "home",
    venda: [],
    searchText: '',
    searchedColumn: '',
    loading: false
  }

  listarvendas = async () => {
    try {
      await this.setState({ loading: true });
      const response = await api.get('passagens', {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      })
      this.setState({ venda: response.data, loading: false })     
    } catch (error) {
      expiredToken(error)
    }
  }

  async componentDidMount (){
    this.listarvendas()
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        render: text => text.substring(0,5)
      },
      {
        title: 'Registro',
        dataIndex: 'dt_compra',
        key: 'dt_compra',
        ...this.getColumnSearchProps('dt_compra')
      },
      {
        title: 'Barco',
        dataIndex: 'embarcacao_nome',
        key: 'embarcacao_nome',
        ...this.getColumnSearchProps('embarcacao_nome')
      },
      {
        title: 'Acomodação',
        dataIndex: 'acomodacao_nome',
        key: 'acomodacao_nome',
        ...this.getColumnSearchProps('acomodacao_nome')
      },
      {
        title: 'Origem',
        dataIndex: 'cidade_origem',
        key: 'cidade_origem',
        ...this.getColumnSearchProps('cidade_origem')
      },
      {
        title: 'Destino',
        dataIndex: 'cidade_destino',
        key: 'cidade_destino',
        ...this.getColumnSearchProps('cidade_destino')
      },
      {
        title: 'Vendedor',
        dataIndex: 'vendedor_usuario',
        key: 'vendedor_usuario',
        ...this.getColumnSearchProps('vendedor_usuario')
      },
      {
        title: 'Cliente',
        dataIndex: 'passageiro_nome',
        key: 'passageiro_nome',
        ...this.getColumnSearchProps('passageiro_nome')
      },
      {
        title: 'Valor',
        dataIndex: 'valor_total'
      },
      {
        title: 'Desconto',
        dataIndex: 'valor_desconto'
      },
      {
        title: 'Embarque',
        dataIndex: 'embarque'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        ...this.getColumnSearchProps('status')
      },
      {
        title: 'Plataforma',
        dataIndex: 'plataforma',
        key: 'plataforma',
        ...this.getColumnSearchProps('plataforma')
      },
    ];
    
    return (
      <Layout>
        <Content style={{ padding: '24px', margin: 90, background: '#fff' }}>
          <Table columns={columns} dataSource={this.state.venda} rowKey="id" loading={this.state.loading} pagination={{ size: 'small' }}/>
          <small className="d-block text-right mt-3">
            <Button onClick={this.listarvendas}>Atualizar</Button>
          </small>
        </Content>
      </Layout>
    )
  }

}
