import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import './dist/css/bootstrap.css'
import './dist/css/offcanvas.css'
import $ from 'jquery'
import Bootstrap from 'bootstrap'
import Login from './pages/Login.js'
import Barco from './pages/Barco.js'
import Rota from './pages/Rota.js'
import Linha from './pages/Linha.js'
import Usuario from './pages/Usuario.js'
import Viagem from './pages/Viagem.js'
import Agencia from './pages/Agencia.js'
import Vendedor from './pages/Vendedor.js'
import Iniciar from './pages/Iniciar.js'
import Galeria from './pages/Galeria'
import '../node_modules/pure-css-loader/dist/css-loader.css'
import Navigation from './components/header'
import { getToken } from './util/helpers'
import Cidade from './pages/Cidade'

class App extends Component {
  render() {
      const useAccessToken = getToken();
      if (!useAccessToken) return <Iniciar></Iniciar>;
      return (
          <BrowserRouter>
            <div>
              <Navigation/>
              <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/barco" component={Barco} />
                <Route path="/logar" component={Iniciar} />
                <Route path="/rota" component={Rota} />
                <Route path="/linha" component={Linha} />
                <Route path="/usuario" component={Usuario} />
                <Route path="/viagem" component={Viagem} />
                <Route path="/agencia" component={Agencia} />
                <Route path="/vendedor" component={Vendedor} />
                <Route path="/galeria" component={Galeria} />
                <Route path="/cidade" component={Cidade} />
              </Switch>
            </div>
          </BrowserRouter>
      )
  }
}

export default App;
