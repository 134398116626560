import React, { Component } from 'react'
import 'antd/dist/antd.css';
import './header.css';
import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Link } from 'react-router-dom';

const { Header, Content, Footer } = Layout;

export default class Navigation extends Component {

  render() {
      const iconStyle = { fontSize: 22 };
      return (
        <Header>
          <div className="logo" />
          <Menu
            onClick={this.onClick}
            theme="dark"
            mode="horizontal"
            style={{ lineHeight: '64px' }}
          > 
            <Menu.Item key="passagem">
              <Link to="/">
                <Icon type="qrcode" style={iconStyle} />
                <span>Passagem</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="cidade">
              <Link to="/cidade">
                <Icon type="environment" style={iconStyle}></Icon>
                <span>Cidade</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="barco">
              <Link to="/barco">
                <Icon type="dashboard" style={iconStyle}/>
                <span>Barco</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="viagem">
              <Link to="/viagem">
                <Icon type="book" style={iconStyle}/>
                <span>Viagem</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="rota">
              <Link to="/rota">
                <Icon type="compass" style={iconStyle}/>
                <span>Rota</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="agencia">
              <Link to="/agencia">
                <Icon type="shop" style={iconStyle}/>
                <span>Agência</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="usuario">
              <Link to="/usuario">
                <Icon type="team" style={iconStyle}/>
                <span>Vendedores</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="vendedor">
              <Link to="/vendedor">
                <Icon type="file-done" style={iconStyle}/>
                <span>Contrato</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="galeria">
              <Link to="/galeria">
                <Icon type="camera" style={iconStyle}/>
                <span>Galeria</span>
              </Link>
            </Menu.Item>
          </Menu>
        </Header>
      )
  }
}
