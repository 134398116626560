import React, { Component } from 'react'
import api from "../services/api"
import { expiredToken, getToken } from "../util/helpers";
import $ from 'jquery';

import { message } from "antd";

export default class ModalAgencia extends Component {

  state = {
      itens: [],
      cnpj: '',
      nome: '',
      descricao: '',
      rodape: '',
  }

  async componentDidMount () { }

  InputNome = e => {
    this.setState({ nome: e.target.value })
  }

  InputCnpj = e => {
    this.setState({ cnpj: e.target.value })
  }

  InputDescricao = e => {
    this.setState({ descricao: e.target.value })
  }

  InputRodape = e => {
    this.setState({ rodape: e.target.value })
  }

  enviaForm = async e => {
    e.preventDefault()
    try {
      const body = {
        'nome': this.state.nome,
        'cnpj': this.state.cnpj, 
        'cabecalho': this.state.descricao, 
        'rodape': this.state.rodape
      };
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      
      await api.post('agencias', body, { headers });    
      message.success('Cadastrado com sucesso!', 4);
    } catch (error) {
      console.log(error.response);
      expiredToken(error);
      message.error(error.response.data.error, 4);
    } finally {
      this.props.update();
      $('.modal').modal('hide')
    }
  }

  render() {

      return (
        <div className="modal fade" id="modalAgencia" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Novo Registro</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form onSubmit={this.enviaForm}>
                    <div className="modal-body">
                          <div className="form-group">
                            <label>Nome:</label>
                            <input type="text" className="form-control" placeholder="Nome" value={this.state.nome} onChange={this.InputNome} />
                            <label>CNPJ:</label>
                            <input type="text" className="form-control" placeholder="CNPJ" value={this.state.cnpj} onChange={this.InputCnpj} />
                            <label>Cabeçalho:</label>
                            <input type="text" className="form-control" placeholder="Cabeçalho" value={this.state.descricao} onChange={this.InputDescricao} />
                            <label>Rodapé:</label>
                            <input type="text" className="form-control" placeholder="Rodapé" value={this.state.rodape} onChange={this.InputRodape} />
                          </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">Salvar</button>
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    </div>
              </form>
            </div>
          </div>
        </div>
      )
  }
}
