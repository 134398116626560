import React, { Component } from 'react'
import api from "../services/api"
import $ from 'jquery'
import { Exception } from 'handlebars'
import { expiredToken, getToken } from '../util/helpers'
import moment from 'moment'

import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import { DatePicker } from 'antd';
import { Form } from 'antd';
import { Select } from 'antd';
import { InputNumber } from "antd";
import { Switch, message } from 'antd';

const { Option } = Select;

export default class ModalAcomodacao extends Component {

  state = {
    itens: [],
    dtsaida: '',
    dtchegada: '',
    qtdepassagens: '',
    idbarco: 0,
    idrota: '',
    barcos: [],
    rotas: [],
    linhas: [],
    count: 1,
    loading: false,
    visible: false,
    weekendFlag: true
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  async componentDidMount() {
    this.listarBarcos()
  }

  listarBarcos = async () => {
    try {
      const response = await api.get('embarcacoes', {
        headers: { Authorization: `Bearer ${getToken()}` }
      })
      this.setState({ barcos: response.data })
      
    } catch (error) {
      expiredToken(error)
    }
  }

  listarRotas = async () => {
    try {
      const response = await api.get(`embarcacoes/${this.state.idbarco}/rotas`, {
        headers: { Authorization: `Bearer ${getToken()}`}
      });
      this.setState({ rotas: response.data });

      let aux = [];
      for (let i = 0; i < this.state.rotas.length; i++) {
        if (this.state.rotas[i].trecho_id == null)
          aux.push(this.state.rotas[i])
      }

      this.setState({ linhas: aux });
    } catch (error) {
      expiredToken(error);
    }
  }

  InputDtSaida = (date, dateString) => {
    this.setState({ dtsaida: dateString })
  }

  InputDtChegada = (date, dateString) => {
    this.setState({ dtchegada: dateString })
  }

  InputIdBarco = async (value, option) => {
    await this.setState({ idbarco: option.props.id })
    this.listarRotas()
  }

  InputIdRota = (value, option) => {
    this.setState({ idrota: option.props.id })
  }

  InputQtdePassagens = e => {
    this.setState({ qtdepassagens: e.target.value })
  }

  enviaForm = async e => {
    this.setState({ loading: true });

    var qtd = this.state.count;
    var intervalo = Number(document.getElementById('intervalo').value)
    var fds = this.state.weekendFlag;
    console.log(fds)
    var dtSaida = this.state.dtsaida
    var dtChegada = this.state.dtchegada
    var dataSaida = new Date(dtSaida)
    var dataChegada = new Date(dtChegada)

    e.preventDefault();

    try {
      for (let i = 0; i < qtd; i++) {
        let body = {
          'rota_id': this.state.idrota, 
          'dt_saida': dtSaida, 
          'dt_chegada': dtChegada, 
          'ativo': 1
        }
        
        await api.post('viagens', body, { headers: { Authorization: `Bearer ${getToken()}` }});
  
        dataSaida = new Date(dataSaida.getFullYear(), dataSaida.getMonth(), dataSaida.getDate() + intervalo, dataSaida.getHours(), dataSaida.getMinutes())
        dataChegada = new Date(dataChegada.getFullYear(), dataChegada.getMonth(), dataChegada.getDate() + intervalo, dataChegada.getHours(), dataChegada.getMinutes())
  
        if (!fds) {
          if (dataSaida.getDay() == 6) {
            dataSaida = new Date(dataSaida.getFullYear(), dataSaida.getMonth(), dataSaida.getDate() + 2, dataSaida.getHours(), dataSaida.getMinutes())
            dataChegada = new Date(dataChegada.getFullYear(), dataChegada.getMonth(), dataChegada.getDate() + 2, dataChegada.getHours(), dataChegada.getMinutes())
          }
          else if (dataSaida.getDay() == 0) {
            dataSaida = new Date(dataSaida.getFullYear(), dataSaida.getMonth(), dataSaida.getDate() + 1, dataSaida.getHours(), dataSaida.getMinutes())
            dataChegada = new Date(dataChegada.getFullYear(), dataChegada.getMonth(), dataChegada.getDate() + 1, dataChegada.getHours(), dataChegada.getMinutes())
          }
        }
  
        let mesSaida = (dataSaida.getMonth() + 1) > 9 ? dataSaida.getMonth() + 1 : "0" + (dataSaida.getMonth() + 1)
        let diaSaida = dataSaida.getDate() > 9 ? dataSaida.getDate() : "0" + dataSaida.getDate()
  
        let mesChegada = (dataChegada.getMonth() + 1) > 9 ? dataChegada.getMonth() + 1 : "0" + (dataChegada.getMonth() + 1)
        let diaChegada = dataChegada.getDate() > 9 ? dataChegada.getDate() : "0" + dataChegada.getDate()
  
        let horaSaida = dataSaida.getHours() > 9 ? dataSaida.getHours() : "0" + dataSaida.getHours()
        let minutoSaida = dataSaida.getMinutes() > 9 ? dataSaida.getMinutes() : "0" + dataSaida.getMinutes()
      
        let horaChegada = dataChegada.getHours() > 9 ? dataChegada.getHours() : "0" + dataChegada.getHours()
        let minutoChegada = dataChegada.getMinutes() > 9 ? dataChegada.getMinutes() : "0" + dataChegada.getMinutes()
        
        dtSaida = `${dataSaida.getFullYear()}-${mesSaida}-${diaSaida} ${horaSaida}:${minutoSaida}`
        dtChegada = `${dataChegada.getFullYear()}-${mesChegada}-${diaChegada} ${horaChegada}:${minutoChegada}`
      }

      message.success('Salvo com sucesso!', 4);
    } catch (error) {
      console.log(error.response)
      expiredToken(error);
      message.error(error.response.data.error, 4);
    } finally {
      this.setState({ loading: false, visible: false });
      this.props.update();
    }
  }

  render() {
    const { visible, loading } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const { empresa } = this.props;
    const barcos = this.state.barcos.map(item => <Option id={item.id} key={item.id} value={item.nome}> {item.nome} </Option>);
    const rotas = this.state.linhas.map(item => <Option id={item.id} key={item.id} value={item.id}> {item.cidade_origem} - {item.cidade_destino} </Option>);

    return (
      <div>
        <Button type="primary" onClick={this.showModal}> Nova viagem </Button>
        <Modal
          centered={true}
          width="350px"
          visible={visible}
          title="Inserir nova viagem"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Return
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={this.enviaForm}>
              Submit
            </Button>,
          ]}
        >
          <Form {...formItemLayout} onSubmit={this.handleSubmit} layout="vertical">
            <Form.Item>
              <Select
                showSearch
                style={{ width: 300 }}
                placeholder="Selecione o barco"
                optionFilterProp="value"
                onChange={this.InputIdBarco}
                filterOption={(input, option) =>
                  option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                { barcos }
              </Select>
            </Form.Item>
            <Form.Item>
              <Select
                style={{ width: 300 }}
                placeholder="Selecione a rota"
                onChange={this.InputIdRota}
              >
                { rotas }
              </Select>
            </Form.Item>
            <Form.Item>
              <DatePicker showTime={{format: "HH:mm", defaultValue:moment('00:00', "HH:mm")}} format="YYYY-MM-DD HH:mm" onChange={this.InputDtSaida} placeholder="Selecione a data de saída" style={{ 'width': "300px" }}/>
            </Form.Item>
            <Form.Item>
              <DatePicker showTime={{format: "HH:mm", defaultValue:moment('00:00', "HH:mm")}} format="YYYY-MM-DD HH:mm" onChange={this.InputDtChegada} placeholder="Selecione a data de chegada" style={{ 'width': "300px" }}/>
            </Form.Item>
            <Form.Item label="Quantidade">
              <InputNumber id="qtd" min={1} defaultValue={1} onChange={(value => this.setState({ count: value }))} />
            </Form.Item>
            <Form.Item label="Intervalo em dias">
              <InputNumber id="intervalo" min={1} defaultValue={1} disabled={ this.state.count > 1 ? false : true }/>
            </Form.Item>
            <Form.Item label="Incluir finais de semana">
              <Switch onChange={checked => this.setState({ weekendFlag: checked })} disabled={ this.state.count > 1 ? false : true } defaultChecked />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}