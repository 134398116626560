import React from 'react';
import { Tag, Col, Row, Card, Badge } from 'antd';

const pStyle = {
  fontSize: 16,
  color: 'rgba(0,0,0,0.85)',
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};

const DescriptionItem = ({ title, content }) => (
  <div
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
      color: 'rgba(0,0,0,0.65)',
    }}
  >
    <p
      style={{
        marginRight: 8,
        display: 'inline-block',
        color: 'rgba(0,0,0,0.85)',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

export default class BoatProfile extends React.Component {
  state = {
    boat: {}
  };

  componentDidMount () {
    this.setState({ boat: this.props.boat });
  }

  componentDidUpdate (prevProps) {
    if (this.props.boat.id !== prevProps.boat.id) {
      this.setState({ boat: this.props.boat });
    }
  }

  render() {
    return (
      <div>
        <p style={{ ...pStyle, margin: '10px' }}>{this.state.boat.nome}</p>
        <Row>
          <Col span={8}>
            <Card
              hoverable
              style={{ width: 240, margin: '10px' }}
              cover={<img alt={this.state.boat.nome} src={this.state.boat.imagem_url || "https://i.pinimg.com/originals/c7/46/b0/c746b0469d665ed861a4beb8188975c9.png"} />}
            >
              <Card.Meta title={`ID #${this.state.boat.id}`} />
            </Card>
          </Col>
          <Col span={16}>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Card hoverable title="Dados gerais" size="small" style={{ margin: '10px' }}>
          <Row>
            <Col span={8}>
              <DescriptionItem title="Nome" content={this.state.boat.nome} />
            </Col>
            <Col span={8}>
              <DescriptionItem title="Tipo" content={<Tag closable={false} color="#1890ff">{this.state.boat.tipo_embarcacao}</Tag>} />
            </Col>
            <Col span={8}>
              <DescriptionItem 
                title="Status" 
                content={this.state.boat.ativo ? 
                  <Badge color="#87d068" status="processing" text="Disponível" /> : 
                  <Badge status="default" text="Inativo" />} 
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <DescriptionItem title="Capacidade" content={this.state.boat.capacidade_total} />
            </Col>
            <Col span={8}>
              <DescriptionItem title="Disponíveis" content={this.state.boat.qtd_passagem} />
            </Col>
            <Col span={8}>
              <DescriptionItem 
                title="Venda online" 
                content={this.state.boat.ecommerce ? 
                  <Badge color="#87d068" status="processing" text="Disponível" /> : 
                  <Badge status="default" text="Inativo" />} 
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <DescriptionItem title="Criado em" content={(new Date(this.state.boat.dt_registro)).toLocaleString('pt-br')} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Última atualização" content={(new Date(this.state.boat.dt_modificacao)).toLocaleString('pt-br')} />
            </Col>
          </Row>
        </Card>
        <Row>
          <Col span={24}>
            <Card hoverable style={{ margin:"10px" }} title="Descrição" size="small">{this.state.boat.descricao || "Not available"}</Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card hoverable style={{ margin:"10px" }} title="Serviços" size="small">{this.state.boat.servicos || "Not available"}</Card>
          </Col>
        </Row>
      </div>
    );
  }
}
