import React, { Component } from 'react'
import api from '../services/api'
import ModalUsuario from '../components/modalUsuario'
import { expiredToken, getToken } from "../util/helpers";

import { Layout, Button } from "antd";

const { Content } = Layout;

export default class Usuario extends Component {
  state = {
      page: "Usuário",
      itens: [],
      nome: '',
      rg: '',
      perfil: '',
      perfis: ['Proprietário', 'Vendedor Interno', 'Vendedor externo', 'Dono de agência'],
  }

  fetchUsers = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      }
      const response = await api.get('usuarios', { headers: headers });
      this.setState({ itens: response.data.filter(value => value.perfil !== 'passageiro') });      
    } catch (error) {
      expiredToken(error);
    }
  }

  InputNome = e => {
    this.setState({ nome: e.target.value })
  }


  excluirUsuario = async(nm_usuario) => {}

  async componentDidMount() {
    this.fetchUsers()
  }

  render() {
    return (
      <Layout>
        <Content style={{ marginTop: 70 }}>
          <ModalUsuario />
          <div className="my-3 p-3 bg-white rounded shadow-sm" style={{ width: '70%', float: 'none', margin: '0 auto' }}>
            <small className="d-block text-right mt-3" style={{marginBottom: '10px'}}>
              <Button type="default" onClick={this.fetchUsers} style={{marginRight: '5px'}}> Atualizar </Button>
              <Button type="primary" data-toggle="modal" data-target="#modalUsuario"> Novo registro </Button>
            </small>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Usuario</th>
                  <th scope="col">Perfil</th>
                  <th scope="col">Agência</th>
                  <th scope="col">Telefone</th>
                  <th scope="col">E-mail</th>
                  <th scope="col">Última venda</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.itens.map( item => (
                    <tr key={item.usuario}>
                      <th scope="row">{item.usuario}</th>
                      <td>{item.perfil}</td>
                      <td>{item.agencia_nome}</td>
                      <td>{item.telefone}</td>
                      <td>{item.email}</td>
                      <td>{item.last_sale}</td>
                      <td>
                        <Button type="danger" onClick={this.excluirUsuario} > Inativar </Button>
                      </td>
                    </tr>
                ))
              }
              </tbody>
            </table>
          </div>
        </Content>
      </Layout>
    )
  }

}
