import React, { Component } from 'react'
import api from '../services/api'
import ModalLinha from '../components/modalLinha'
import Header from '../components/header.js'
import $ from 'jquery'

export default class Linha extends Component {
  state = {
    page: "Linha",
    linhas: [],
    rotas: [],
    idLinha: '',
    CNPJ: ''
  }

  listarLinhas = async () => {
    const response = await api.get('/linha/listarLinhas')
    this.setState({ linhas: response.data })
  }

  listarRotas = async (id) => {
    // document.getElementById("load").classList.add("is-active")
    var vm = this
    await api.get('/linha/buscarRotasPorLinha/' + id)
      .then(function (response) {
        vm.setState({ rotas: response.data })
      })
      .then(function () {
        // document.getElementById("load").classList.remove("is-active")
      })
  }

  atualiza = async () => {
    this.listarLinhas()
    this.listarRotas(this.state.idLinha)
  }
  inputLinha = async e => {
    await this.setState({ idLinha: e.target.value })
    this.listarRotas(this.state.idLinha)
  }

  salvarLinha = async (id_linha) => {
    var vlTransporte = document.getElementById(id_linha + ".inputTransporte").value;
    var vlAlimentacao = document.getElementById(id_linha + ".inputAlimentacao").value;
    const response = await api.post('linha/editar', { 'vl_transporte': vlTransporte, 'vl_alimentacao': vlAlimentacao, 'id_linha': id_linha })
      .then(function (response) {
        alert('Salvo com sucesso!')
      })
  }
  excluirLinha = async (id_linha) => {
    const response = await api.post('linha/excluir/' + id_linha)
      .then(function (response) {
        alert('Linha excluída!');
      })
  }

  async componentDidMount() {
    document.getElementById("linha").classList.add("active")
    this.listarLinhas()
  }

  render() {
    return (
      <div>
        <div className="container">
          <ModalLinha />
          <div className="my-3 p-3 bg-white rounded shadow-sm">
            <h6 className="border-bottom border-gray pb-2 mb-0">Gerenciar Linhas</h6>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text" htmlFor="inputGroupSelect01">Linha Principal</label>
              </div>
              <select className="custom-select" id="inputGroupSelect01" defaultValue="0" onChange={this.inputLinha}>
                <option defaultValue="0">Selecione uma linha...</option>
                {
                  this.state.linhas.map(item => (
                    <option value={item.id_linha} key={item.id_linha}>{item.origem + " / " + item.destino}</option>
                  ))
                }
              </select>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Origem</th>
                  <th scope="col">Destino</th>
                  <th scope="col">Linha Principal</th>
                  <th scope="col">Transporte</th>
                  <th scope="col">Alimentação</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.rotas.map(item => (
                    <tr key={item.id_linha}>
                      <th scope="row">{item.id_linha}</th>
                      <td>{item.origem}</td>
                      <td>{item.destino}</td>
                      <td>{item.trechosaida == null ? "Linha Principal" : item.trechosaida + " / " + item.trechochegada}</td>
                      <td><input id={item.id_linha + ".inputTransporte"} type="number" className="form-control" defaultValue={item.vl_transporte} /></td>
                      <td><input id={item.id_linha + ".inputAlimentacao"} type="number" className="form-control" defaultValue={item.vl_alimentacao} /></td>
                      <td><button type="button" className="btn btn-outline-success btn-sm" onClick={this.salvarLinha.bind(this, item.id_linha)}>Salvar</button> / <button type="button" className="btn btn-outline-danger btn-sm" onClick={this.excluirLinha.bind(this, item.id_linha)}>Inativar</button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>

            <small className="d-block text-right mt-3">
              - <button className="btn btn-link" onClick={this.atualiza}>Atualizar Lista</button>
              - <button className="btn btn-link" data-toggle="modal" data-target="#modalLinha" >Novo Registro</button>
            </small>
          </div>
        </div>
      </div>
    )
  }

}
