import React, { Component } from 'react'
import api from '../services/api'
import ModalGaleria from '../components/modalGaleria';
import { expiredToken, getToken } from '../util/helpers'

import 'antd/dist/antd.css';
import { Layout, List, Button, message, Popconfirm, Tag } from 'antd';
import { Select } from 'antd';
import Navigation from '../components/header';

const { Content } = Layout;
const { Option } = Select;

export default class Galeria extends Component {
  state = {
    page: "Viagem",
    itens: [],
    barcos: [],
    idBarco: 0,
    idViagem: '',
    selectedRowKeys: [],
    loading: false,
  }

  fetchImages = async (idBarco) => {
    try {
      await this.setState({ loading: true });
      const response = await api.get(`embarcacoes/${idBarco}/images`, { headers: { Authorization: "Bearer " + getToken() } });
      console.log(response.data)
      this.setState({ itens: response.data, loading: false });
    } catch (error) {
      expiredToken(error);
    }
  }

  listarBarcos = async () => {
    try {
      const response = await api.get('embarcacoes', { headers: { Authorization: "Bearer " + getToken() } })
      this.setState({ barcos: response.data })
    } catch (error) {
      expiredToken(error)
    }
  }

  UpdateAfterInsert = () => {
    if (this.state.idBarco) {
      this.fetchImages(this.state.idBarco);
    }
  }

  InputNome = e => {
    this.setState({ nome: e.target.value })
  }

  InputIdBarco = async (value, option) => {
    await this.setState({ idBarco: option.props.id });
    this.fetchImages(this.state.idBarco);
  }

  setDefaultImage = async (e) => {
    const { id } = e.target;

    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      await api.patch(`embarcacoes/images/${id}/setDefault`, {}, { headers: headers });
      message.success('Image defined as default', 5);
      this.fetchImages(this.state.idBarco);
    } catch (error) {
      console.log(error.response);
      expiredToken(error);
      message.error(error.response.data.error, 5);
    }
  }

  remove = async (id) => {
    await this.setState({ loading: true });
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      await api.delete(`embarcacoes/images/${id}`, { headers: headers });
      message.success('Imagem removida com sucesso!', 4);
    } catch (error) {
      expiredToken(error);
      message.error(error.response.data.error, 4);
    } finally {
      await this.setState({ loading: false });
      this.fetchImages(this.state.idBarco);
    }
  }

  async componentDidMount() {
    this.listarBarcos();
  }

  render() {
    const barcos = this.state.barcos.map(item => <Option id={item.id} key={item.id} value={item.nome}> {item.nome} </Option>);

    return (
      <Layout>
        <Content style={{ padding: '0 50px', marginTop: 70 }} >
          <div className="container">
            <div className="my-3 p-3 bg-white rounded shadow-sm" >
              <div>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Selecione o barco para exibir suas viagens"
                  optionFilterProp="value"
                  onChange={this.InputIdBarco}
                  filterOption={(input, option) =>
                    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {barcos}
                </Select>
              </div>
              <div className="d-block text-right mt-3">
                {/* <Button onClick={this.UpdateAfterInsert}>Atualizar</Button> */}
                <ModalGaleria update={this.UpdateAfterInsert} />
              </div>
              <List
                itemLayout="horizontal"
                size="large"
                dataSource={this.state.itens}
                renderItem={item => (
                  <List.Item
                  // key={item.title}
                  // actions={
                  //    !loading && [
                  //       <IconText type="star-o" text="156" key="skeleton-star-o" />,
                  //       <IconText type="like-o" text="156" key="skeleton-like-o" />,
                  //       <IconText type="message" text="2" key="skeleton-message" />
                  //    ]
                  // }
                  >
                    <img
                      width={272}
                      alt="logo"
                      src={item.imagem_url}
                    />
                    <Button type="defau" style={{ marginLeft: 10 }} id={item.id} onClick={this.setDefaultImage}> Set as default </Button>
                    <Popconfirm title="Sure to delete?" onConfirm={() => this.remove(item.id)}>
                      <Button type="danger" style={{ marginLeft: 5 }} > Delete </Button>
                    </Popconfirm>
                    <Tag color="green" visible={item.is_default} style={{ marginLeft: 5 }}> Default </Tag>
                    {/* <Skeleton loading={loading} active avatar>
                           <List.Item.Meta
                              avatar={<Avatar src={item.avatar} />}
                              title={<a href={item.href}>{item.title}</a>}
                              description={item.description}
                           />
                           {item.content}
                        </Skeleton> */}
                  </List.Item>
                )}
              />
            </div>
          </div>
        </Content>
      </Layout>
    )
  }

}
