import { Table, Row, Col, Empty, Button, Tooltip } from 'antd';
import React from 'react';
import api from '../services/api';
import { getToken, expiredToken } from '../util/helpers';
import PortsTable from '../components/cityPortsTable'

class Cidade extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      isLoadingData: false,
      currentSelectedCity: null
    };
  }

  async fetchAllCities() {
    try {
      this.setState({ isLoadingData: true });
      const headers = {
        Authorization: `Bearer ${getToken()}`
      }
      const { data } = await api.get('cidades', { headers });
      this.setState({ isLoadingData: false, cities: data });
    } catch (error) {
      expiredToken(error);
    }
  }

  async componentDidMount() {
    this.fetchAllCities();
  }

  render() {
    const columns = [
      {
        title: 'Nome',
        dataIndex: 'nome',
        key: 'name',
        ellipsis: true,
        render: (value, record) => (
          <Tooltip title={value}>
            <span>{value}</span>
          </Tooltip>
        )
      },
      {
        title: 'Sigla',
        dataIndex: 'sigla',
        key: 'initials',
      },
      {
        title: 'UF',
        dataIndex: 'uf',
        key: 'state'
      },
      {
        title: '',
        dataIndex: 'viewPorts',
        key: 'viewPorts',
        render: (value, record) => (
          <Button size="small" onClick={(e) => this.setState({ currentSelectedCity: record })}>
            Visualizar portos
          </Button>
        )
      }
    ];

    return (
      <Row type="flex" justify="space-around">
        <Col span={8}>
          <div style={{ background: '#FFFFFF', padding: '30px', margin: '30px' }}>
            <Table 
              columns={columns} 
              dataSource={this.state.cities} 
              loading={this.state.isLoadingData} 
              pagination={{ size: 'small'}} 
              size="small"
              title={() => "Cidades"}
            />
          </div>
        </Col>
        <Col span={16}>
          <div style={{ background: '#FFFFFF', padding: '30px', margin: '30px' }}>
            {
              this.state.currentSelectedCity ? 
                <PortsTable city={this.state.currentSelectedCity}></PortsTable> :
                <Empty description={<span>Selecione uma cidade na tabela ao lado</span>}></Empty>
            }
          </div>
        </Col>
      </Row>
    );
  }
}

export default Cidade;