import React, { Component } from 'react'
import api from "../services/api"
import { expiredToken, getToken } from "../util/helpers";
import $ from 'jquery';
import InputMask from 'react-input-mask';

import { message } from "antd";

export default class ModalUsuario extends Component {

  state = {
      itens: [],
      login: '',
      email: '',
      telefone: '',
      senha: '',
      perfil: '',
      agencia_id: ''
  }

  async componentDidMount () {
    this.fetchAgencies();
  }

  InputLogin = e => {
    this.setState({ login: e.target.value.toLowerCase() })
  }
  InputEmail = e => {
    this.setState({ email: e.target.value.toLowerCase() })
  }
  InputTelefone = e => {
    this.setState({ telefone: e.target.value })
  }
  InputSenha = e => {
    this.setState({ senha: e.target.value })
  }
  InputPerfil = e => {
    this.setState({ perfil: e.target.value })
  }
  InputAgencia = e => {
    this.setState({ agencia_id: e.target.value })
  }

  fetchAgencies = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      const response = await api.get('agencias', { headers: headers });
      this.setState({ itens: response.data });
    } catch (error) {
      expiredToken(error);
    }
  }

  enviaForm = async e => {
    e.preventDefault()
    try {
      const body = {
        'usuario': this.state.login,
        'agencia_id': this.state.agencia_id,
        'email': this.state.email,
        'telefone': this.state.telefone.replace(/[^0-9]/gi, ''),
        'perfil': this.state.perfil,
        'senha': this.state.senha,
        'senhaConf': this.state.senha
      };
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      console.log(body);
      await api.post('usuarios/cadastro', body, { headers: headers });
      message.success('Usuário cadastrado com sucesso', 4);
    } catch (error) {
      console.log(error.response);
      expiredToken(error);
      let validatorError = error.response.data.errors ? error.response.data.errors[0].msg : null;
      message.error(error.response.data.error || validatorError || error.response.data.message, 4);
    } finally {
      $('.modal').modal('hide')
    }
  }

  render() {
      return (
        <div className="modal fade" id="modalUsuario" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Novo Registro</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.enviaForm}>
                <div className="modal-body">
                  <div className="form-group">
                    <label>Login:</label>
                    <input type="text" className="form-control" placeholder="Login" required value={this.state.login} onChange={this.InputLogin} />
                    <label>E-mail:</label>
                    <input type="text" className="form-control" placeholder={this.state.login ? `${this.state.login}@email.com` : "E-mail"} value={this.state.email} required onChange={this.InputEmail} />
                    <label>Telefone:</label>
                    <InputMask mask="(99) 99999-9999" maskChar={null} className="form-control" placeholder="Telefone" required value={this.state.telefone} onChange={this.InputTelefone} />
                    {/* <input type="text" className="form-control" placeholder="Telefone" required value={this.state.telefone} onChange={this.InputTelefone} /> */}
                    <label>Senha:</label>
                    <input type="password" className="form-control" placeholder="Senha" required value={this.state.senha} onChange={this.InputSenha} />
                    <label htmlFor="inputGroupSelect01">Perfil:</label>
                    <select className="custom-select" id="inputGroupSelect01" required onChange={this.InputPerfil}>
                      <option value="">Selecione um perfil</option>
                      <option value="dono_embarcacao">Dono de embarcação</option>
                      <option value="vendedor_interno">Vendedor Interno</option>
                      <option value="dono_agencia">Dono de agência</option>
                      <option value="vendedor_externo">Vendedor Externo</option>
                    </select>
                    <label htmlFor="inputGroupSelect01">Agência:</label>
                    <select className="custom-select" id="inputGroupSelect02" required onChange={this.InputAgencia}>
                      <option value="">Selecione uma agência</option>
                      {
                        this.state.itens.map(item => <option key={item.id} value={item.id}>{item.nome}</option>)
                      }                      
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">Salvar</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )
  }
}
