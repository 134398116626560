import React, { Component } from 'react'
import api from '../services/api'
import './iniciar.css'
import Load from '../components/modalLoading.js'

import 'antd/dist/antd.css';
import { Form, Icon, Input, Button, Checkbox } from 'antd';

class Iniciar extends Component {

  state = {
    page: "home",
    venda: [],
    isLoading: false
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields( async (err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        try {
          const { data } = await api.post(
            'usuarios/login', 
            { usuario: values.username, senha: values.password }
          );
          localStorage.setItem('token', data.token);
          window.location.href="/";
        } catch (error) {
          this.setState({ isLoading: false });
          console.log(error);
        }
      } else {
        console.log(err);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
 
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <img style={{width: "300px", height: "110px", marginBottom: "50px", marginTop: '100px'}} src={`${process.env.PUBLIC_URL}/navegam.png`} />
        <Form.Item>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input.Password
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.isLoading} disabled={this.state.isLoading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    )
  }

}

export default Form.create({ name: 'normal_login' })(Iniciar);