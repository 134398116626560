import React, { Component } from 'react'
import api from "../services/api"
import $ from 'jquery'

export default class ModalLinha extends Component {

  state = {
    itens: [],
    cidades: [],
    transporte: 0,
    alimentacao: 0,
    cidadeOrigem: '',
    cidadeDestino: '',
    trecho: '',
    rotas: [],
    linhas: [],
    linhasPrincipais: [],
    idLinha: '',
  }

  async componentDidMount() {
    const response = await api.get('/linha/listarLinhas')
    this.setState({ linhasPrincipais: response.data })
    this.listarCidades()
  }

  listarCidades = async () => {
    const response = await api.get('rota/listarCidades')
    this.setState({ cidades: response.data })
  }

  InputTransporte = e => {
    this.setState({ transporte: e.target.value })
  }

  InputAlimentacao = e => {
    this.setState({ alimentacao: e.target.value })
  }

  InputCidadeOrigem = e => {
    this.setState({ cidadeOrigem: e.target.value })
  }

  InputCidadeDestino = e => {
    this.setState({ cidadeDestino: e.target.value })
  }

  InputIdLinha = e => {
    this.setState({ idLinha: e.target.value })

  }

  enviaForm = async e => {
    e.preventDefault()
    if(this.state.idLinha == '' || this.state.idLinha == 0){
      const response = await api.post('linha/inserirLinha', { 'id_cidade_origem': this.state.cidadeOrigem, 'id_cidade_destino': this.state.cidadeDestino, 'vl_transporte': this.state.transporte, 'vl_alimentacao': this.state.alimentacao })
      if(response.data.affectedRows > 0)
        alert('Inserido com sucesso!')
    }
    else{
      const response = await api.post('linha/inserirRota', { 'id_cidade_origem': this.state.cidadeOrigem, 'id_cidade_destino': this.state.cidadeDestino, 'vl_transporte': this.state.transporte, 'vl_alimentacao': this.state.alimentacao, 'id_trecho': this.state.idLinha })
      if(response.data.affectedRows > 0)
        alert('Inserido com sucesso!')
    }
    $('.modal').modal('hide')
  }

  render() {
    const { empresa } = this.props;

    return (
      <div className="modal fade" id="modalLinha" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Novo Registro</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={this.enviaForm}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="formGroupExampleInput2">Transporte: </label>
                      <input type="number" className="form-control" value={this.state.transporte} onChange={this.InputTransporte} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="formGroupExampleInput2">Alimentação:</label>
                      <input type="number" className="form-control" value={this.state.alimentacao} onChange={this.InputAlimentacao} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlSelect1">Origem:</label>
                      <select className="form-control" defaultValue="0" onChange={this.InputCidadeOrigem}>
                        <option value="0">Selecione cidade...</option>
                        {
                          this.state.cidades.map(item => (
                            <option key={item.id_cidade} value={item.id_cidade}>{item.nm_cidade}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlSelect1">Destino:</label>
                      <select className="form-control" defaultValue="0" onChange={this.InputCidadeDestino}>
                        <option value="0">Selecione cidade...</option>
                        {
                          this.state.cidades.map(item => (
                            <option key={item.id_cidade} value={item.id_cidade}>{item.nm_cidade}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <fieldset>

                </fieldset>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      
                      <label htmlFor="formGroupExampleInput2">Trecho de:</label>
                      <select className="form-control" defaultValue="0" onChange={this.InputIdLinha}>
                        <option value="0">Escolha a Linha Principal</option>
                        {
                          this.state.linhasPrincipais.map(item => (
                            <option key={item.id_linha} value={item.id_linha}>[ID: {item.id_linha}] - {item.origem} - {item.destino}</option>
                          ))
                        }
                      </select>
                      <legend>Obs: Só escolha uma linha principal se for inserir uma rota que pertence a essa linha<br></br>Obs2: Atualize a página ao inserir uma linha</legend>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">Salvar</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
