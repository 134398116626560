import React, { Component } from 'react'
import api from "../services/api"
import { expiredToken, getToken } from "../util/helpers";
import $ from 'jquery'

import { Button, message } from "antd";

export default class ModalVendedor extends Component {

  state = {
      barcos: [],
      agencias: [],
      acomodacoes: [],
      id_agencia: '',
      id_barco: '',
      id_acomodacao: ''
  }

  async componentDidMount (){
    this.listarBarcos();
    this.listarAgencias();
  }

  listarBarcos = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      const response = await api.get('embarcacoes', { headers: headers });
      this.setState({ barcos: response.data });      
    } catch (error) {
      expiredToken(error);
    }
  }

  listarAgencias = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      const response = await api.get('agencias', { headers: headers });
      this.setState({ agencias: response.data });      
    } catch (error) {
      expiredToken(error);
    }
  }

  listarAcomodacoes = async (id) => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      const response = await api.get(`embarcacoes/${id}/acomodacoes`, { headers: headers });
      this.setState({ acomodacoes: response.data });      
    } catch (error) {
      expiredToken(error);
    }
  }

  InputAgencia = e => {
    this.setState({ id_agencia: e.target.value })
  }

  InputBarco = async e => {
    await this.setState({ id_barco: e.target.value });
    this.listarAcomodacoes(this.state.id_barco);
  }

  InputAcomodacao = e => {
    this.setState({ id_acomodacao: e.target.value })
  }

  enviaForm = async e => {
    e.preventDefault()
    try {
      const body = {
        embarcacao_acomodacao_id: this.state.id_acomodacao,
        agencia_id: this.state.id_agencia
      };
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      
      await api.post('embarcacao-agencia', body, { headers: headers });
      message.success('Registrado com sucesso!', 4);      
    } catch (error) {
      expiredToken(error);
      message.error(error.response.data, 4);
    } finally {
      this.props.update();
      $('.modal').modal('hide');
    }
  }

  render() {
      return (
        <div className="modal fade" id="modalVendedor" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Novo Registro</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.enviaForm}>
                <div className="modal-body">
                  <div className="form-group">
                    <label>Barco:</label>
                    <select className="custom-select" id="inputGroupSelect01" required onChange={this.InputBarco}>
                      <option value="">Selecione um barco</option>
                      {
                        this.state.barcos.map( item => (
                          <option value={item.id} key={item.id}>{item.nome}</option>
                        ))
                      }
                    </select>
                    <label>Acomodação:</label>
                    <select className="custom-select" id="inputGroupSelect02" required onChange={this.InputAcomodacao}>
                      <option value="">Selecione uma acomodação</option>
                      {
                        this.state.acomodacoes.map( item => (
                          <option value={item.id} key={item.id}>{item.acomodacao_nome}</option>
                        ))
                      }
                    </select>
                    <label>Agência:</label>
                    <select className="custom-select" id="inputGroupSelect03" required onChange={this.InputAgencia}>
                      <option value="">Selecione uma agência</option>
                      {
                        this.state.agencias.map( item => (
                          <option value={item.id} key={item.id}>{item.nome}</option>
                        ))
                      }
                    </select>   
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">Salvar</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )
  }
}
