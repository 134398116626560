import React, { Component } from 'react'
import api from '../services/api'
import ModalVendedor from '../components/modalVendedor'
import { expiredToken, getToken } from "../util/helpers";
import $ from 'jquery'

import { Layout, Button } from "antd";
import Navigation from '../components/header';
const { Content } = Layout;

export default class Vendedor extends Component {
  state = {
    page: "Vendedor",
    itens: [],
    nome: '',
    rg: '',
    agencia_id: '',
    agencias: [],
  }

  listarAgencias = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      const response = await api.get('agencias', { headers: headers });
      this.setState({ agencias: response.data }); 
    } catch (error) {
      expiredToken(error);
    }
  }

  listarVendedores = async () => { }

  fetchShipsByAgency = async (id) => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      const response = await api.get(`agencias/${id}/embarcacoes`, { headers: headers });
      this.setState({ itens: response.data })
    } catch (error) {
      console.log(error.response)
      expiredToken(error);
    }
  }

  InputNome = e => {
    this.setState({ nome: e.target.value })
  }

  InputAgencia = async e =>{
    await this.setState({ agencia_id: e.target.value});
    this.fetchShipsByAgency(this.state.agencia_id);
  }

  excluirVendedor = async (id) => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      const response = await api.patch(`agencias/contrato/${id}/disable`, { headers: headers });
      this.UpdateAfterInsert();
    } catch (error) {
      console.log(error.response)
      expiredToken(error);
    }
  }

  UpdateAfterInsert = async () => {
    if ( this.state.agencia_id ) {
      this.fetchShipsByAgency(this.state.agencia_id);
    }
  }

  async componentDidMount() {
    this.listarAgencias();
  }

  render() {
    return (
      <Layout>
        <Content style={{ padding: '0 50px', marginTop: 70 }}>
          <div className="container">
            <ModalVendedor update={this.UpdateAfterInsert}/>
            <div className="my-3 p-3 bg-white rounded shadow-sm">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <label className="input-group-text" htmlFor="inputGroupSelect01">Agência</label>
                </div>
                <select className="custom-select" id="inputGroupSelect01" defaultValue="0" onChange={this.InputAgencia}>
                  <option value="">Selecione uma agência...</option>
                  {
                    this.state.agencias.map(item => (
                      <option value={item.id} key={item.id}>{item.nome}</option>
                    ))
                  }
                </select>
              </div>
              <small className="d-block text-right mt-3" style={{ marginBottom: 10 }}>
                <Button type="primary" data-toggle="modal" data-target="#modalVendedor"> Novo registro </Button>
              </small>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Embarcação</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Acomodação</th>
                    <th scope="col">Cota</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.itens.map(item => (
                      <tr key={item.id}>
                        <th scope="row">{item.id}</th>
                        <td>{item.embarcacao_nome}</td>
                        <td>{item.tipo_embarcacao}</td>
                        <td>{item.acomodacao_nome}</td>
                        <td>{item.cota_agencia}</td>
                        <td>
                          <button type="button" className="btn btn-outline-danger btn-sm" onClick={this.excluirVendedor.bind(this, item.id)}>Inativar</button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </Content>
      </Layout>
    )
  }

}
