import React, { Component } from 'react'
import api from "../services/api"
import $ from 'jquery'

export default class ModalLoading extends Component {

  state = {
      nome: ''
  }

  startLoad = () => {
    document.getElementById("load").classList.add("is-active")
  }

  endLoad = () => {
    document.getElementById("load").classList.remove("is-active")
  }

  render() {
      const {empresa} = this.props;

      return (
          <div className="loader loader-default" data-text="Aguarde..." id="load"></div>
      )
  }
}
