import React, { Component } from 'react'
import api from "../services/api"
import { expiredToken, getToken } from "../util/helpers";
import $ from 'jquery'

export default class modalRota extends Component {

  state = {
    itens: [],
    barcos: [],
    cidades: [],
    barcoId: 0,
    transporte: 0,
    alimentacao: 0,
    cidadeOrigem: 0,
    cidadeDestino: 0,
    trecho: 0,
    rotas: [],
    linhas: [],
    linhasPrincipais: [],
    idLinha: 0,
  }

  async componentDidMount() {
    this.fetchCities()
    this.fetchBoats()
  }

  fetchBoats = async () => {
    try {
      const response = await api.get('embarcacoes', { headers: { Authorization: "Bearer " + getToken()}});
      this.setState({ barcos: response.data });      
    } catch (error) {
      expiredToken(error);
    }
  }

  fetchLines = async () => {
    try {
      const response = await api.get(`embarcacoes/${this.state.barcoId}/linhas`, { headers: { Authorization: "Bearer " + getToken() } });
      this.setState({ linhasPrincipais: response.data });      
    } catch (error) {
      expiredToken(error);
    }
  }

  static getDerivedStateFromProps(props, state) {
    state.rotas = props.rotas
    state.linhas = [];
    for (let i = 0; i < state.rotas.length; i++) {
      if (typeof state.rotas[i] !== 'undefined' && state.rotas[i].trechochegada == null)
        state.linhas.push(state.rotas[i])
    }
    return null;
  }

  inputBarco = async e => {
    await this.setState({ barcoId: e.target.value });
    this.fetchLines();
  }

  InputTransporte = e => {
    this.setState({ transporte: e.target.value })
  }

  InputAlimentacao = e => {
    this.setState({ alimentacao: e.target.value })
  }

  InputCidadeOrigem = e => {
    this.setState({ cidadeOrigem: e.target.value })
  }

  InputCidadeDestino = e => {
    this.setState({ cidadeDestino: e.target.value })
  }

  InputIdLinha = e => {
    this.setState({ idLinha: e.target.value })
  }

  InputTrecho = e => {
    if (e.target.value == 0) {
      this.setState({ trecho: 'null' })
    }
    else
      this.setState({ trecho: e.target.value })
  }

  fetchCities = async () => {
    try {
      const response = await api.get('cidades', { headers: { Authorization: "Bearer " + getToken() } });
      this.setState({ cidades: response.data });      
    } catch (error) {
      expiredToken(error);
    }
  }

  enviaForm = async e => {
    e.preventDefault();
    
    let requestBody = { 
      'cidade_origem_id': Number(this.state.cidadeOrigem), 
      'cidade_destino_id': Number(this.state.cidadeDestino), 
      'valor_transporte': Number(this.state.transporte), 
      'valor_alimentacao': Number(this.state.alimentacao), 
      'trecho_id': Number(this.state.idLinha) 
    };

    requestBody.trecho_id = requestBody.trecho_id ? requestBody.trecho_id : null;
    
    try {
      
      const response = await api.post(
        `embarcacoes/${this.state.barcoId}/linhas`, 
        requestBody, 
        { headers: { Authorization: "Bearer " + getToken() } }
      );

      $('.modal').modal('hide')
      this.fetchLines();
      this.props.update()      
    } catch (error) {
      expiredToken(error);
    }
  }

  render() {
    const { barco } = this.props;

    return (
      <div className="modal fade" id="modalRota" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Novo Registro</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={this.enviaForm}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlSelect1">Barco:</label>
                      <select className="form-control" defaultValue="0" onChange={this.inputBarco}>
                        <option value="0">Selecione o barco...</option>
                        {
                          this.state.barcos.map(item => (
                            <option key={item.id} value={item.id}>{item.nome}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">                
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="formGroupExampleInput2">Transporte: </label>
                      <input type="number" className="form-control" value={this.state.transporte} onChange={this.InputTransporte} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="formGroupExampleInput2">Alimentação:</label>
                      <input type="number" className="form-control" value={this.state.alimentacao} onChange={this.InputAlimentacao} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlSelect1">Origem:</label>
                      <select className="form-control" defaultValue="0" onChange={this.InputCidadeOrigem}>
                        <option value="0">Selecione cidade...</option>
                        {
                          this.state.cidades.map(item => (
                            <option key={item.id} value={item.id}>{item.nome}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlSelect1">Destino:</label>
                      <select className="form-control" defaultValue="0" onChange={this.InputCidadeDestino}>
                        <option value="0">Selecione cidade...</option>
                        {
                          this.state.cidades.map(item => (
                            <option key={item.id} value={item.id}>{item.nome}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="formGroupExampleInput2">Trecho de:</label>
                      <select className="form-control" defaultValue="0" onChange={this.InputTrecho}>
                        <option value="0">Selecione a rota (se for um trecho)</option>
                        {
                          this.state.linhas.map(item => (
                            <option key={item.id_rota} value={item.id_rota}>[{item.id_rota}] - {item.nm_cidade_origem} - {item.nm_cidade_destino}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <h3>OU</h3> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="formGroupExampleInput2">Trecho de:</label>
                      <select className="form-control" defaultValue="0" onChange={this.InputIdLinha}>
                        <option value="0">Selecione a Linha Principal</option>
                        {
                          this.state.linhasPrincipais.map(item => (
                            <option key={item.id} value={item.id}>[ID: {item.id}] - {item.cidade_origem} - {item.cidade_destino}</option>
                          ))
                        }
                      </select>
                      <legend style={{marginTop: '20px'}}>(Atenção!) Se uma linha NÃO for selecionada, uma NOVA LINHA será criada a partir dos dados acima</legend>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">Salvar</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
