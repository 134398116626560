function expiredToken (error) {
   if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.href = "/"
   }
}

function getToken () {
   return localStorage.getItem('token')
}

module.exports = {
   expiredToken,
   getToken
}