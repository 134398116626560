import React, { Component } from 'react'
import api from "../services/api"
import { expiredToken, getToken } from "../util/helpers";
import $ from 'jquery'

export default class modalRotaDup extends Component {

  state = {
    itens: [],
    barcos: [],
    cidades: [],
    barcoId: 0,
    newBoatId: 0,
    linhasPrincipais: [],
    idLinha: 0,
  }

  async componentDidMount() {
    this.fetchBoats()
  }

  fetchBoats = async () => {
    try {
      const response = await api.get('embarcacoes', { headers: { Authorization: "Bearer " + getToken()}});
      this.setState({ barcos: response.data });      
    } catch (error) {
      expiredToken(error);
    }
  }

  fetchLines = async () => {
    try {
      const response = await api.get(`embarcacoes/${this.state.barcoId}/linhas`, { headers: { Authorization: "Bearer " + getToken() } });
      this.setState({ linhasPrincipais: response.data });      
    } catch (error) {
      expiredToken(error);
    }
  }

  inputBarco = async e => {
    await this.setState({ barcoId: e.target.value });
    this.fetchLines();
  }

  inputNewBarco = async e => {
    await this.setState({ newBoatId: e.target.value });
  }

  InputIdLinha = e => {
    this.setState({ idLinha: e.target.value })
  }

  enviaForm = async e => {
    e.preventDefault();
    
    try {
      
      await api.post(
        `embarcacoes/${this.state.newBoatId}/rotas?boat=${this.state.barcoId}&line=${this.state.idLinha}`, 
        {}, 
        { headers: { Authorization: "Bearer " + getToken() } }
      );
    
      $('.modal').modal('hide')
      this.fetchLines();
      this.props.update()      
    } catch (error) {
      expiredToken(error);
    }
  }

  render() {
    const { barco } = this.props;

    return (
      <div className="modal fade" id="modalRotaDup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Duplicar rotas</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={this.enviaForm}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlSelect1">Copiar de:</label>
                      <select className="form-control" defaultValue="0" onChange={this.inputBarco}>
                        <option value="0">Selecione o barco...</option>
                        {
                          this.state.barcos.map(item => (
                            <option key={item.id} value={item.id}>{item.nome}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <select className="form-control" defaultValue="0" onChange={this.InputIdLinha}>
                        <option value="0">Selecione a Linha Principal</option>
                        {
                          this.state.linhasPrincipais.map(item => (
                            <option key={item.id} value={item.id}>[ID: {item.id}] - {item.cidade_origem} - {item.cidade_destino}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlSelect1">Para:</label>
                      <select className="form-control" defaultValue="0" onChange={this.inputNewBarco}>
                        <option value="0">Selecione o barco...</option>
                        {
                          this.state.barcos.map(item => (
                            <option key={item.id} value={item.id}>{item.nome}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">Salvar</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
