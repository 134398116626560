import React, { Component } from 'react'
import api from '../services/api'
import ModalBarco from '../components/modalBarco'
import DynamicFormItem from '../components/dynamicFormItem'
import { expiredToken, getToken } from '../util/helpers'
import BoatProfile from '../components/boatProfile';
import 'antd/dist/antd.css';
import './barco.css';
import { Table, Input, InputNumber, Popconfirm, Form, Tooltip, message, Switch, Button, Row, Col, Empty, Tag } from 'antd';

const EditableContext = React.createContext();

class EditableCell extends Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class Barco extends Component {

  constructor (props) {
    super(props);
    this.state = {
      page: "Barco",
      data: [],
      editingKey: '',
      nestedData: {},
      isLoading: {},
      currentSelectedBoat: null,
      isTableLoading: false
    }
    this.columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        width: '10%',
        editable: false,
      },
      {
        title: 'Embarcação',
        dataIndex: 'nome',
        width: '25%',
        editable: true,
        ellipsis: true,
        render: (text, record) => (
          <Tooltip title={text}>
            <span>{text}</span>
          </Tooltip>
        )
      },
      {
        title: 'Telefone',
        dataIndex: 'telefone',
        editable: true,
        render: (text, record) => (
          <span>{text || <Tag>Not available</Tag>}</span>
        )
      },
      {
        title: 'E-commerce',
        dataIndex: 'ecommerce',
        width: '15%',
        editable: false,
        render: (value, record) => {
          return (
            <Switch checked={record.ecommerce ? true : false} onChange={e => {
              this.setShipEcommerceStatus(record.key, e);
            }}></Switch>
            // <Popconfirm title="Sure to enable/disable?" onConfirm={(e) => console.log(e)}>
            // </Popconfirm>
          )
        }
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <a
                    onClick={() => this.save(form, record.key)}
                    style={{ marginRight: 8 }}
                  >
                    Save
                  </a>
                )}
              </EditableContext.Consumer>
              <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <div>
              <a disabled={editingKey !== ''} style={{marginRight: '10px'}} onClick={() => this.edit(record.key)}>
                Edit
              </a>
              <Popconfirm title="Sure to delete?" onConfirm={() =>  this.excluirBarco(record.key)}>
                <a style={{ marginRight: '10px' }}>Delete</a>
              </Popconfirm>
              <Button 
                size="small" 
                onClick={(e) => {
                  this.setState({ currentSelectedBoat: record });
                }}>
                Visualizar
              </Button>
            </div>
          );
        },
      },
    ];
  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);
      
      if (index > -1) {
        this.salvarBarco(key, row);
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: '' });
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: '' });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  listarBarcos = async () => {
    try {
      this.setState({ isTableLoading: true, currentSelectedBoat: null });
      const response = await api.get('embarcacoes', { headers: { Authorization: "Bearer " + getToken() } })
      this.setState({ data: response.data.map(item => {
        item.key = item.id;
        return item;
      }) })
      this.setState({ isTableLoading: false });
    } catch (error) {
      expiredToken(error)
    }
  }

  excluirBarco = async(id_barco) => {
    try {
      await api.delete(`embarcacoes/${id_barco}`, { headers: { Authorization: `Bearer ${getToken()}` } });
      message.success('Removido com sucesso!', 4);
      this.listarBarcos();      
    } catch (error) {
      expiredToken(error);
      message.error(error.response.data.error, 4);
    }
  }

  salvarBarco = async (key, values) => {
    api.patch(`embarcacoes/${key}`, values, { headers: { Authorization: "Bearer " + getToken() } })
      .then(function(response) {
          console.log(response);
          message.success('Atualizado com sucesso!', 4);
      })
      .catch(error => {
        console.log(error.response);
        expiredToken(error);
        message.error(error.response.data.error);
      })      
    
  }

  setShipEcommerceStatus = async (key, value) => {
    try {
      const body = { ecommerce: value };
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };

      await api.patch(`embarcacoes/${key}`, body, { headers: headers });
      message.success('Salvo com sucesso', 4);
      this.listarBarcos();
    } catch (error) {
      console.log(error.response);
      expiredToken(error);
      message.error(error.response.data.error, 4);
    }
  }

  async componentDidMount() {
    this.listarBarcos()
  }

  async fetchDiscountsByBoat(id) {
    try {
      const headers = { Authorization: `Bearer ${getToken()}` };
      let response = await api.get(`embarcacoes/${id}/descontos`, { headers });
      return response.data;
    } catch (error) {
      expiredToken(error);
    }
  }

  refreshBoatDiscounts = async (id) => {
    const currentNestedData = this.state.nestedData;
    this.setState({ isLoading: { [id]: true }, nestedData: { ...currentNestedData, [id]: null } });
    const data = await this.fetchDiscountsByBoat(id);
    this.setState({ isLoading: { [id]: false }, nestedData: { ...currentNestedData, [id]: data } });
  }

  async handleDiscountDelete (ruleId, boatId) {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      await api.delete(`embarcacoes/${boatId}/descontos/${ruleId}`, { headers });
      this.refreshBoatDiscounts(boatId);
    } catch (error) {
      expiredToken(error);
    }
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: (col.dataIndex === 'capacidade_total' || col.dataIndex === 'qtd_passagem') ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const handleExpand = async (expanded, record) => {
      this.setState({ isLoading: { [record.id]: true } });
      const data = await this.fetchDiscountsByBoat(record.id);
      const updatedNestedData = this.state.nestedData;
      updatedNestedData[record.id] = data;
      this.setState({ nestedData: updatedNestedData });
      this.setState({ isLoading: { [record.id]: false } });
    }

    const expandedRowRender = (record) => {
      const daysColumnRender = (value) => {
        const roundedAge = Math.trunc(value/365);
        const days = value - roundedAge * 365;
        return <div>{`${roundedAge} anos${days ? ` e ${days} dia(s)`: ''}`}</div>
      };
      const columns = [
        { title: 'Id', dataIndex: 'id', key: 'id' },
        { title: 'Min (idade)', dataIndex: 'dias_minimo', render: value => daysColumnRender(value) },
        { title: 'Max (idade)', dataIndex: 'dias_maximo', render: value => daysColumnRender(value) },
        { title: 'TRP (%)', dataIndex: 'percentual_transporte', render: value => value * 100 },
        { title: 'ALM (%)', dataIndex: 'percentual_alimentacao', render: value => value * 100 },
        { title: 'ACM (%)', dataIndex: 'percentual_acomodacao', render: value => value * 100 },
        { title: 'Operation', dataIndex: 'operation', render: (text, rule) => (
          <Popconfirm title="Você deseja remover esta regra?" onConfirm={() => this.handleDiscountDelete(rule.id, record.id)}>
            <Button type="danger" size="small" shape="round">Remover</Button>
          </Popconfirm>
        )}
      ];
      const data = this.state.nestedData[record.id];
      return (
        <div>
          <div style={{ marginBottom: 16 }}>
            <DynamicFormItem update={this.refreshBoatDiscounts} boatId={record.id}></DynamicFormItem>
          </div>
          <Table 
            loading={this.state.isLoading[record.id] && !data} 
            columns={columns} 
            dataSource={this.state.nestedData[record.id]} 
            pagination={false} 
            size="small"
          />
        </div>
      ); 
    }

    return (
      <Row>
        <Col span={12}>
          <div style={{ background: '#FFFFFF', margin: '30px', padding: '30px' }}>
            <Row>
              <Col span={8}>
                <ModalBarco update={this.listarBarcos}/>
              </Col>
              <Col span={8}></Col>
              <Col span={8} style={{ textAlign: 'right' }}>
                <Button size="small" onClick={(e) => this.listarBarcos()}>
                  Reload
                </Button>
              </Col>
            </Row>
            <EditableContext.Provider value={this.props.form}>
              <Table
                loading={this.state.isTableLoading}
                components={components}
                dataSource={this.state.data}
                expandedRowRender={expandedRowRender}
                onExpand={handleExpand}
                columns={columns}
                rowClassName="editable-row"
                pagination={{ size: "small" }}
                rowKey="id"
                size="small"
              />
            </EditableContext.Provider>
          </div>
        </Col>
        <Col span={12}>
          <div style={{ background: '#FFFFFF', margin: '30px', padding: '30px' }}>
            {!this.state.currentSelectedBoat ? <Empty></Empty> : <BoatProfile boat={this.state.currentSelectedBoat}></BoatProfile>}
          </div>
        </Col>
      </Row>
    )
  }
}

export default Form.create()(Barco);