import React from 'react'
import { Form, Button, InputNumber } from 'antd';
import api from '../services/api';
import { expiredToken, getToken } from '../util/helpers';

let id = 0;

class DynamicFieldSet extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      isDisabled: false
    }
  }

  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    // We need at least one passenger
    // if (keys.length === 1) {
    //   return;
    // }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  add = () => {
    this.setState({ isDisabled: true });
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: [0],
    });
  };

  postNewRule = async ({ 
    boatId, 
    dias_minimo,
    dias_maximo,
    percentual_transporte,
    percentual_alimentacao,
    percentual_acomodacao,
  }) => {
    const requestBody = {
      dias_minimo,
      dias_maximo,
      percentual_transporte,
      percentual_alimentacao,
      percentual_acomodacao
    };
    const headers = {
      Authorization: `Bearer ${getToken()}`
    };
    try {
      await api.post(`embarcacoes/${boatId}/descontos`, requestBody, { headers });
      return true;
    } catch (error) {
      expiredToken(error);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        const { discountRules } = values;
        let params = discountRules[0];
        let boatId = this.props.boatId;
        await this.postNewRule({ 
          boatId, 
          dias_minimo: (parseInt(params.min) * 365) + 1,
          dias_maximo: parseInt(params.max) * 365,
          percentual_transporte: parseInt(params.trp) / 100,
          percentual_alimentacao: parseInt(params.alm) / 100,
          percentual_acomodacao: parseInt(params.acm) / 100,
        });
        this.remove(0);
        this.setState({ isLoading: false, isDisabled: false })
        this.props.update(boatId);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
      },
    };
    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => (
      <Form layout="inline" onSubmit={this.handleSubmit} key={k}>
        <Form.Item
          label="Min"
          required={false}
          key={k}
        >
          {getFieldDecorator(`discountRules[${k}]['min']`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                whitespace: true,
                message: "Required",
              },
            ],
          })(<InputNumber size="small" min={0}/>)}
        </Form.Item>
        <Form.Item
          label="Max"
          required={false}
          key={k}
        >
          {getFieldDecorator(`discountRules[${k}]['max']`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                whitespace: true,
                message: "Required",
              },
            ],
          })(<InputNumber size="small" min={0}/>)}
        </Form.Item>
        <Form.Item
          label="TRP (%)"
          required={false}
          key={k}
        >
          {getFieldDecorator(`discountRules[${k}]['trp']`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                whitespace: true,
                message: "Required",
              },
            ],
          })(<InputNumber size="small" min={0} max={100}/>)}
        </Form.Item>
        <Form.Item
          label="ALM (%)"
          required={false}
          key={k}
        >
          {getFieldDecorator(`discountRules[${k}]['alm']`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                whitespace: true,
                message: "Required",
              },
            ],
          })(<InputNumber size="small" min={0} max={100}/>)}
        </Form.Item>
        <Form.Item
          label="ACM (%)"
          required={false}
          key={k}
        >
          {getFieldDecorator(`discountRules[${k}]['acm']`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                whitespace: true,
                message: "Required",
              },
            ],
          })(<InputNumber size="small" min={0} max={100}/>)}
        </Form.Item>
        <Form.Item>
          <Button 
            type="primary" 
            htmlType="submit" 
            loading={this.state.isLoading} 
            disabled={this.state.isLoading}
            style={{ backgroundColor: '#52b788', borderColor: '#52b788' }}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    ));
    return (
      <Form>
        {formItems}
        <Form.Item {...formItemLayoutWithOutLabel}>
          <Button type="primary" onClick={this.add} style={{ width: '60%' }} disabled={this.state.isDisabled}>
            Adicionar regra
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(DynamicFieldSet);
export default WrappedDynamicFieldSet;