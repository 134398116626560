import React, { Component } from 'react'
import api from "../services/api"
import { expiredToken, getToken } from '../util/helpers'

import 'antd/dist/antd.css';
import { Modal, Button, Upload, message, Icon } from 'antd';
import { Form } from 'antd';
import { Select } from 'antd';

const { Option } = Select;

export default class ModalGaleria extends Component {

  state = {
    itens: [],
    idbarco: 0,
    barcos: [],
    files: [],
    loading: false,
    visible: false
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  async componentDidMount() {
    this.listarBarcos()
  }

  listarBarcos = async () => {
    try {
      const response = await api.get('embarcacoes', {
        headers: { Authorization: `Bearer ${getToken()}` }
      })
      this.setState({ barcos: response.data })

    } catch (error) {
      expiredToken(error)
    }
  }

  InputIdBarco = async (value, option) => {
    await this.setState({ idbarco: option.props.id })
  }

  enviaForm = async e => {
    await this.setState({ loading: true });

    try {
      const submit = this.state.files.map(async file => {
        const formData = new FormData();
        formData.append('image', file.originFileObj);
        const headers = {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'multipart/form-data; boundary=${formData._boundary}'
        };
        await api.post(`embarcacoes/${this.state.idbarco}/images`, formData, { headers: headers });
      });

      await Promise.all(submit);
      message.success('Foto(s) armazenada(s) com sucesso!', 4);
    } catch (error) {
      console.log(error);
      expiredToken(error);
      message.error(error.response.data.error, 4);
    } finally {
      await this.setState({ visible: false, loading: false });
      this.props.update();
    }
  }

  onChangeUpload = async (info) => {
    if (info.file.status === 'done') {
      await this.setState({
        files: info.fileList
      })
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };  

  render() {
    const { visible, loading } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const barcos = this.state.barcos.map(item => <Option id={item.id} key={item.id} value={item.nome}> {item.nome} </Option>);

    const props = {
      name: 'file',
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      headers: {
        authorization: 'authorization-text',
      }
    };

    return (
      <div>
        <Button type="primary" onClick={this.showModal}> Upload fotos </Button>
        <Modal
          centered={true}
          width="350px"
          visible={visible}
          title="Inserir novas fotos"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose
          afterClose={() => { this.setState({ files: [] }) }}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Return
            </Button>,
            <Button key="submit" type="primary" loading={this.state.loading} onClick={this.enviaForm}>
              Submit
            </Button>,
          ]}
        >
          <Form {...formItemLayout} onSubmit={this.handleSubmit} layout="vertical">
            <Form.Item>
              <Select
                showSearch
                style={{ width: 300 }}
                placeholder="Selecione o barco"
                optionFilterProp="value"
                onChange={this.InputIdBarco}
                filterOption={(input, option) =>
                  option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {barcos}
              </Select>
            </Form.Item>
            <Form.Item>
              <Upload customRequest={this.dummyRequest} onChange={this.onChangeUpload}>
                <Button style={{ width: 300 }}>
                  <Icon type="upload" /> Click to Upload
               </Button>
              </Upload>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}