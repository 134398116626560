import React from 'react';
import api from '../services/api';
import { getToken, expiredToken } from '../util/helpers';
import { Table, Button, Row, Col, Popconfirm, Avatar, Select, message } from 'antd';
import PortForm from '../components/newCityPortDrawer';

export default class PortsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ports: [],
      isLoading: false,
      cityId: 0,
      isLoadingDelete: {},
      nestedData: {},
      isNestedTableLoading: {},
      boats: [],
      isSelectLoading: false,
      selectedBoatId: {},
      isLoadingButton: {}
    }
    this.updateComponent = this.updateComponent.bind(this);
  }

  async fetchPortsByCityId (id) {
    try {
      this.setState({ isLoading: true });
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      const { data } = await api.get(`cidades/${id}/portos`, { headers });
      this.setState({ isLoading: false, ports: data.portos, cityId: id });
    } catch (error) {
      expiredToken(error);
    }
  }

  async handlePortDelete(id) {
    try {
      this.setState({ isLoadingDelete: { [id]: true } });
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      await api.delete(`portos/${id}`, { headers });
      this.updateComponent();
      this.setState({ isLoadingDelete: { [id]: false } });
    } catch (error) {
      expiredToken(error);
    }
  }

  async updateComponent() {
    this.fetchPortsByCityId(this.state.cityId);
    this.setState({ selectedBoatId: {}, isLoadingButton: {} });
  }

  async componentDidMount() {
    this.fetchPortsByCityId(this.props.city.id);
    this.fetchAllAvailableBoats();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.city.id !== prevProps.city.id) {
      this.fetchPortsByCityId(this.props.city.id);
      this.setState({ selectedBoatId: {}, isLoadingButton: {} });
    }
  }

  async fetchBoatsByPort(id) {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      const { data } = await api.get(`portos/${id}/embarcacoes`, { headers });
      return data.embarcacoes;
    } catch (error) {
      expiredToken(error);
    }
  }

  async fetchAllAvailableBoats() {
    try {
      this.setState({ isSelectLoading: true });
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      const { data } = await api.get('embarcacoes', { headers });
      this.setState({ boats: data, isSelectLoading: false });
    } catch (error) {
      expiredToken(error);
    }
  }

  async attachBoatToPort(boatId, portId) {
    try {
      this.setState({ isLoadingButton: { [portId]: true } });
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };
      await api.post(`portos/${portId}/embarcacoes`, { embarcacao_id: boatId}, { headers });
      this.setState({ isLoadingButton: { [portId]: false } });
      this.updateNestedTable(portId);
    } catch (error) {
      if (error.response.status !== 401) {
        message.warning('Deu merda!');
        return this.updateComponent();
      }
      expiredToken(error);
    }
  }

  async updateNestedTable(id) {
    this.setState({ isNestedTableLoading: { [id]: true } });
    const boats = await this.fetchBoatsByPort(id);
    const currentNestedData = this.state.nestedData;
    currentNestedData[id] = boats;
    this.setState({
      nestedData: currentNestedData,
      isNestedTableLoading: { [id]: false } 
    });
  }

  render () {
    const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Nome',
        dataIndex: 'nome',
        key: 'name',
      },
      {
        title: 'Endereço',
        dataIndex: 'logradouro',
        key: 'address',
        render: (text, record) => (
          <span>{`${record.logradouro}, ${record.numero}, ${record.bairro} - ${record.cep}`}</span>
        )
      },
      {
        title: '',
        key: 'action',
        width: '10%',
        align: 'center',
        render: (text, record) => (
          <Popconfirm title="Você deseja remover este Porto?" onConfirm={() => this.handlePortDelete(record.id)}>
            <Button type="danger" size="small" loading={this.state.isLoadingDelete[record.id]}>Remover</Button>
          </Popconfirm>
        )
      }
    ];

    const handleExpand = async (expanded, record) => {
      this.setState({ isNestedTableLoading: { [record.id]: true } });
      const boats = await this.fetchBoatsByPort(record.id);
      const currentNestedData = this.state.nestedData;
      currentNestedData[record.id] = boats;
      this.setState({
        nestedData: currentNestedData,
        isNestedTableLoading: { [record.id]: false } 
      });
    }

    const expandedRowRender = (record) => {
      const columns = [
        {
          title: '',
          dataIndex: 'imagem_url',
          key: 'avatar',
          render: (value) => (
            <Avatar shape="square" size="large" src={value} />
          ),
          width: '10%',
          align: 'center'
        },
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: 'Nome',
          dataIndex: 'nome',
          key: 'name'
        },
        {
          title: 'Tipo',
          dataIndex: 'tipo_embarcacao',
          key: 'type'
        },
      ];

      const onSelectBoat = (value) => {
        const currentSelectedBoat = this.state.selectedBoatId;
        currentSelectedBoat[record.id] = value;
        this.setState({ selectedBoatId: currentSelectedBoat });
      }

      const onButtonClick = (e) => {
        this.attachBoatToPort(this.state.selectedBoatId[record.id], record.id);
      }
  
      return (
        <div>
          <Table
            size="small"
            columns={columns}
            dataSource={this.state.nestedData[record.id]}
            loading={this.state.isNestedTableLoading[record.id]}
          >
          </Table>
          <Button
            type="primary" 
            disabled={!this.state.selectedBoatId[record.id]}
            onClick={onButtonClick}
            loading={this.state.isLoadingButton[record.id]}
          >
            Adicionar
          </Button>
          <Select
            loading={this.state.isSelectLoading}
            showSearch
            style={{ marginLeft: '10px', width: '240px' }}
            placeholder="Selecione uma embarcação"
            optionFilterProp="children"
            onChange={onSelectBoat}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.state.boats.map(boat => {
              return <Select.Option value={boat.id} key={boat.id}>{boat.nome}</Select.Option>
            })}
          </Select>
        </div>
      );
    }

    return (
      <Table 
        columns={columns}  
        dataSource={this.state.ports} 
        loading={this.state.isLoading}
        pagination={{ size: "small" }}
        expandedRowRender={expandedRowRender}
        onExpand={handleExpand}
        title={() => {
          const { city } = this.props;
          return <Row>
            <Col span={6} style={{ textAlign: "center" }}>
              {`${city.nome}${city.sigla.trim() ? ` (${city.sigla})` : ""}${city.uf ? `, ${city.uf}` : ""}`}
            </Col>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}>
              <PortForm city={city} update={this.updateComponent}></PortForm>
            </Col>
          </Row>
        }}
        bordered
      />
    );
  }
}