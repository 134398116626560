import React, { Component } from 'react'
import api from '../services/api'
import ModalAgencia from '../components/modalAgencia'
import Navigation from '../components/header'
import { expiredToken, getToken } from '../util/helpers'

import { Layout, message, Popconfirm, Button } from 'antd';
import { Table, Input, Form, Switch } from 'antd';
const { Content } = Layout;
const { TextArea } = Input;

const EditableContext = React.createContext();

class EditableCell extends Component {
  getInput = () => {
    if (this.props.inputType === 'text-area') {
      return <TextArea rows={3} />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: false,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class Agencia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "Agencia",
      itens: [],
      nome: '',
      cnpj: '',
      descricao: '',
      rodape: '',
      editingKey: '',
      loading: false
    };
    this.columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        width: '5%',
        editable: false,
      },
      {
        title: 'Nome',
        dataIndex: 'nome',
        width: '10%',
        editable: true,
      },
      {
        title: 'CNPJ',
        dataIndex: 'cnpj',
        width: '10%',
        editable: true,
      },
      {
        title: 'Cabeçalho',
        dataIndex: 'cabecalho',
        width: '20%',
        editable: true,
      },
      {
        title: 'Rodapé',
        dataIndex: 'rodape',
        width: '20%',
        editable: true,
      },
      {
        title: 'E-commerce',
        dataIndex: 'ecommerce',
        width: '10%',
        editable: false,
        render: (value, record) => {
          return (
            <Switch checked={record.ecommerce ? true : false} onChange={e => {
              this.setAgencyEcommerceStatus(record.key, e);
            }}></Switch>
            // <Popconfirm title="Sure to enable/disable?" onConfirm={(e) => console.log(e)}>
            // </Popconfirm>
          )
        }
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        width: '10%',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => this.save(form, record.key)}
                    style={{ marginRight: 5 }}
                  >
                    Save
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                <Button size="small" >
                  Cancel
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <div>
              <Button size="small" disabled={editingKey !== ''} onClick={() => this.edit(record.key)} style={{marginRight: '5px'}}>
                Edit
              </Button>
              <Popconfirm title="Sure to delete?" onConfirm={() =>  this.excluirAgencia(record.key)}>
                <Button type="danger" size="small" >
                  Delete
                </Button>
              </Popconfirm>
            </div>
          );
        },
      }
    ];
  }

  isEditing = record => { return record.key === this.state.editingKey};

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.itens];
      const index = newData.findIndex(item => key === item.key);
      
      if (index > -1) {
        this.salvarAgencia(key, row);
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ itens: newData, editingKey: '' });
      } else {
        newData.push(row);
        this.setState({ itens: newData, editingKey: '' });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }
  
  InputNome = e => {
    this.setState({ nome: e.target.value })
  }
  InputCnpj = e => {
    this.setState({ cnpj: e.target.value })
  }
  InputDescricao = e => {
    this.setState({ descricao: e.target.value })
  }
  InputRodape = e => {
    this.setState({ rodape: e.target.value })
  }

  listarAgencias = async () => {
    await this.setState({ loading: true });
    try {
      const response = await api.get('agencias', { headers: { Authorization: "Bearer " + getToken() }})
      this.setState({ 
        itens: response.data.map(item => {
          item.key = item.id;
          return item;
        }), 
        loading: false
      });   
    } catch (error) {
      expiredToken(error)
    }
  }

  buscarAgencia = async (id_agencia) => { }

  salvarAgencia = async (key, values) => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      }

      await api.patch(`agencias/${key}`, values, { headers: headers });
      message.success('Salvo com sucesso', 4);
      this.listarAgencias();
    } catch (error) {
      console.log(error.response);
      expiredToken(error);
      message.error(error.response.data.error, 4);
    }
  }

  setAgencyEcommerceStatus = async (key, value) => {
    try {
      const body = { ecommerce: value };
      const headers = {
        Authorization: `Bearer ${getToken()}`
      };

      await api.patch(`agencias/${key}`, body, { headers: headers });
      message.success('Salvo com sucesso', 4);
      this.listarAgencias();
    } catch (error) {
      console.log(error.response);
      expiredToken(error);
      message.error(error.response.data.error, 4);
    }
  }

  excluirAgencia = async(id_agencia) => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`
      }
      await api.delete(`agencias/${id_agencia}`, { headers: headers });
      message.success('Removido com sucesso', 4);
    } catch (error) {
      console.log(error.response);
      expiredToken(error);
      message.error(error.response.data.error, 4);      
    } finally {
      this.listarAgencias();
    }
  }

  async componentDidMount() {
    this.listarAgencias()
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: (col.dataIndex === 'cabecalho' || col.dataIndex === 'rodape') ? 'text-area' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return (
      <Layout>
        <Content style={{ padding: '0 50px', marginTop: 70 }}>
          <div className="my-3 p-3 bg-white rounded shadow-sm" style={{ width: '80%', float: 'none', margin: '0 auto' }}>
            <ModalAgencia update={this.listarAgencias}/>
            <div className="my-3 p-3 bg-white rounded shadow-sm">
              <h6 className="border-bottom border-gray pb-2 mb-0">Gerenciar Agências</h6>              
              <small className="d-block text-right mt-3" style={{marginBottom: '10px'}}>
                <Button type="default" onClick={this.listarAgencias} style={{marginRight: '5px'}}> Atualizar </Button>
                <Button type="primary" data-toggle="modal" data-target="#modalAgencia"> Novo registro </Button>
              </small>
              <EditableContext.Provider value={this.props.form}>
                <Table
                  components={components}
                  dataSource={this.state.itens}
                  columns={columns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: this.cancel,
                  }}
                  rowKey="id"
                  loading={this.state.loading}
                />
              </EditableContext.Provider>
            </div>
          </div>
        </Content>
      </Layout>
    )
  }

}

export default Form.create()(Agencia);